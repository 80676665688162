import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  private program: string = "";
  private reports: any = {};
  public openProgramsNav = true;
  public reportsRouter: boolean = false;

  getProgram() {
    return this.program;
  }

  getReports() {
    return this.reports;
  }

  constructor(public authservice: AuthorizationService, public dashboard: DashboardComponent, public localStorage: LocalStorageService, private route: ActivatedRoute, private router: Router) {
    this.reportsRouter = this.router.url.startsWith("/reports");
    this.route.queryParams.subscribe(params => {
      this.program = params['program'];

      this.reports = {};

      let urlMappings = this.authservice.getUrlMappings().filter((curr: any) => {
        return curr.program === this.program
      });
  
      urlMappings.forEach((report: any) => {
        let reportName = report.report;
        let roleList = report.roleList.$values;
        this.reports = {
            [reportName]: roleList,
            ...this.reports
        };
      })
    });
  }

  getReportNames() {
    return Object.keys(this.reports).sort();
  }

  getReportRoles(key: string) {
    return this.reports[key].sort();
  }

  ngOnInit(): void {
  }

  ngDoCheck(){
  }

  toggleProgramsNav(){
    this.openProgramsNav = !this.openProgramsNav;
    this.dashboard.openProgramsNav = !this.dashboard.openProgramsNav;
  }
}
