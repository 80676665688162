import { Component, OnInit } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-tennstar',
  templateUrl: './tennstar.component.html',
  styleUrls: ['./tennstar.component.css']
})
export class TennstarComponent implements OnInit {

  constructor(public dashboard: DashboardComponent, public authservice: AuthorizationService, public localStorage:LocalStorageService) { }

  ngOnInit(): void {
  }

}
