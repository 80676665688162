import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as pbi from 'powerbi-client';
import { LocalStorageService } from 'angular-2-local-storage';
import * as FileSaver from 'file-saver';
import { HttpResponseCheck } from "src/app/services/httpresponse-check/htttpResponseCheck.service"
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PowerbiService {
  private powerbi: pbi.service.Service;
  private embedReportId = '';
  private embedToken = '';
  private embedAccessToken = '';
  private embedUrl = '';
  private report!: pbi.Embed;
  private tinNumber= '232106895';

  public ExportTypes = {
    PDF: {
      id: 1,
      ext: ".pdf"
    },
    PPTX: {
      id: 0,
      ext: ".pptx"
    },
  }

  private toastrConfig = {
    closeButton: true,
    timeOut: 7000,
    extendedTimeOut: 2000,
    progressBar: true,
    tapToDismiss: false
  }

  private toastrConfigNoClose = {
    ...this.toastrConfig,
    disableTimeOut: true
  }

  constructor(private http: HttpClient, private localStorage: LocalStorageService, private toastr: ToastrService, private httpResCheck: HttpResponseCheck) {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  }

  public setEmbedReportId(reportId: string) {
    this.embedReportId = reportId;
  }

  public getEmbedReportId() {
    return this.embedReportId;
  }

  public setEmbedToken(embedToken: string) {
    this.embedToken = embedToken;
  }

  public getEmbedToken() {
    return this.embedToken;
  }

  public setEmbedAccessToken(embedAccessToken: string) {
    this.embedAccessToken = embedAccessToken;
  }

  public getEmbedAccessToken() {
    return this.embedAccessToken;
  }

  public setEmbedUrl(embedUrl: string) {
    this.embedUrl = embedUrl;
  }

  public getEmbedUrl() {
    return this.embedUrl;
  }

  // callPowerBiEmbedAPI(workspaceId: string, reportId: string, datasetId: string, drillThroughWorkspaceId: string, drillThroughReportId:string, drillThroughDatasetId:string, program:string, userType: string, tinNumber: string, email: string) {
  //   const url = environment.powerBiEmbedURL + '?workspaceId=' + workspaceId + '&reportId=' + reportId + '&datasetId=' + datasetId + '&drillThroughWorkspaceId=' + drillThroughWorkspaceId + '&drillThroughReportId=' + drillThroughReportId + '&drillThroughDatasetId=' + drillThroughDatasetId + '&program=' + program + '&userType=' + userType + '&tinNumber=' + tinNumber + '&email=' + email;
  //   return this.http.get(url, {
  //     headers: {
  //       'Authorization': "Bearer " + this.localStorage.get('access_token')
  //     },
  //     params: new HttpParams({fromObject: {
  //       workspaceId: workspaceId,
  //       reportId: reportId,
  //       datasetId: datasetId,
  //       drillThroughWorkspaceId: drillThroughWorkspaceId,
  //       drillThroughReportId: drillThroughReportId,
  //       drillThroughDatasetId: drillThroughDatasetId,
  //       program: program,
  //       userType: userType,
  //       tinNumber: tinNumber,
  //       email: email,
  //     }}),
  //     observe: 'response',
  //     responseType: 'json'
  //   });
  // }

  async getPowerBiEmbedInfo(urlLinkName: string, program: string, roleName: string, tinNumber: string){
    let httpGet = this.http.get(environment.powerBiEmbedURL, {
      headers: {
        'Authorization': "Bearer " + this.localStorage.get('access_token')
      },
      params: new HttpParams({fromObject: {
        urlLinkName: urlLinkName,
        program: program,
        roleName: roleName,
        tinNumber: tinNumber
      }}),
      observe: 'response',
      responseType: 'json'
    }).toPromise();

    httpGet.catch(reason => {
      this.httpResCheck.NotifyError();
    });

    let response = await httpGet;
    
    this.httpResCheck.CheckHttpResponseNotify(response);
    Object.entries(response.body || {}).forEach(([key, value]) => {
      if (key == 'reportId') {
        this.setEmbedReportId(value)
      }
      if (key == 'embedUrl') {
        this.setEmbedUrl(value);
      }
      if (key == 'embedToken') {
        this.setEmbedToken(value);
      }
      if (key == 'accessToken') {
        this.setEmbedAccessToken(value);
      }
    });
  }

  removeReport() {
    if(this.report){
      this.powerbi.reset(this.report.element);
      // this.report.element.innerHTML = "";
      this.localStorage.set('reportLoaded', false);
    }
  }

  showReport() {
    const model = pbi.models;

    // Configuration used to describe the what and how to embed
    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details
    let config = {
      type: 'report',
      tokenType: model.TokenType.Embed,
      accessToken: this.embedToken,
      embedUrl: this.embedUrl,
      id: this.embedReportId,
      permissions: model.Permissions.All,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        bars:{
          actionBar:{
              visible:true
          }
        },
        panes: {
          pageNavigation: {
              visible: true,
              position: model.PageNavigationPosition.Left
          },
        }
      }
    };

    let reportContainer = <HTMLElement>document.getElementById('reportContainer');
    this.report = this.powerbi.embed(reportContainer, config);

    // Report.off removes a given event handler if it exists.
    this.report.off("loaded");

    // Report.on will add an event handler which prints to Log window.
    this.report.on("loaded",  () => {
      console.log("Report Loaded");
      this.localStorage.set('reportLoaded', true);
    });
  }

  reloadReport(){
    this.report.reload();
  }

  toggleFullScreen(){
    this.report.fullscreen();
  }

  exportReportToFile(urlLinkName: string, program: string, roleName: string, tinNumber: string, fileFormat: any){
    let activeToast: ActiveToast<any> = this.toastr.info('Report being exported. Please wait...', 'Export', this.toastrConfigNoClose);

    let response = this.http.get(environment.powerBiExportURL, {
      headers: {
        'Authorization': "Bearer " + this.localStorage.get('access_token')
      },
      params: new HttpParams({fromObject: {
        urlLinkName: urlLinkName,
        program: program,
        roleName: roleName,
        tinNumber: tinNumber,
        fileFormat: fileFormat.id
      }}),
      observe: 'response',
      responseType: 'json'
    }).toPromise();

    response.catch(reason => {
      this.toastr.clear(activeToast.toastId);
      this.httpResCheck.NotifyError();
    })

    response.then(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value]) => {
        if (key == 'exportId') {
          this.exportReportToFileWaitAndWrite(urlLinkName, program, roleName, tinNumber, value, fileFormat, activeToast);
        }
      });
    });
  }

  async exportReportToFileWaitAndWrite(urlLinkName: string, program: string, roleName: string, tinNumber: string, exportId: string, fileFormat: any, activeToast: ActiveToast<any>){
    let exportReady: string;
    do{
      await this.sleep(2000);
      exportReady = await this.exportReportToFileWait(urlLinkName, program, roleName, tinNumber, exportId);
    }while(exportReady=="false");
    
    if(exportReady == "error"){
      console.log("exportstatus: error");
      this.toastr.clear(activeToast.toastId);
      this.toastr.error('Report failed.', 'Export', this.toastrConfig);
      return;
    }

    let response = this.http.get(environment.powerBiExportFileURL, {
      headers: {
        'Authorization': "Bearer " + this.localStorage.get('access_token'),
      },
      params: new HttpParams({fromObject: {
        urlLinkName: urlLinkName,
        program: program,
        roleName: roleName,
        tinNumber: tinNumber,
        exportId: exportId
      }}),
      observe: 'response',
      responseType: 'blob'
    }).toPromise();
    
    response.catch(reason => {
      this.toastr.clear(activeToast.toastId);
      this.httpResCheck.NotifyError();
    });

    response.then((response) => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      console.log(response.headers);
      const fileName =  response.headers.get('Content-Disposition')?.split('filename=')[1];
      let exportFileStream = response.body;
      if(exportFileStream !== null){
        // let fileName = prompt("Enter the file name to be save the report: ");
        FileSaver.saveAs(exportFileStream, `${fileName}${fileFormat.ext}`);
        this.toastr.clear(activeToast.toastId);
        this.toastr.success('Report exported successfully.', 'Export', this.toastrConfig);
      }
    },(error => {
      this.toastr.clear(activeToast.toastId);
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  async exportReportToFileWait(urlLinkName: string, program: string, roleName: string, tinNumber: string, exportId: string): Promise<string>{
    let  waitStatus: string = "error";

    let response = await this.http.get(environment.powerBiExportStatusURL, {
      headers: {
        'Authorization': "Bearer " + this.localStorage.get('access_token')
      },
      params: new HttpParams({fromObject: {
        urlLinkName: urlLinkName,
        program: program,
        roleName: roleName,
        tinNumber: tinNumber,
        exportId: exportId
      }}),
      observe: 'response',
      responseType: 'json'
    }).toPromise();

    this.httpResCheck.CheckHttpResponseNotify(response);

    Object.entries(response.body || {}).forEach(([key, value]) => {
      if (key == 'exportStatus') {
        waitStatus = value;
      }
    });
    
    return waitStatus;
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
