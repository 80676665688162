<div class="container-fluid reports-container-main" [ngClass]="{'reports-container': !this.reportsRouter}">
    <div class="row" *ngIf="this.reportsRouter">
        <div class="col-sm-6" style="margin-top: 1rem; margin-left: 27%;">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" style="text-decoration:None;">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ this.getProgram()}} </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row reports-main">
        <app-programs [ngClass]="{'col-sm-3': openProgramsNav&&this.reportsRouter, 'col-sm-5': openProgramsNav&&!this.reportsRouter, 'app-programs-close': !openProgramsNav}"></app-programs>
        <div class="middle-separator" *ngIf="this.reportsRouter"></div>
        <div *ngIf="!this.reportsRouter" class="d-flex collapser col-sm-1 justify-content-center">
            <div class="d-flex align-self-start justify-content-center collapser-main" (click)="this.toggleProgramsNav()" title="Toggle Programs Navigation">
                <span class="d-flex align-self-center justify-content-center" *ngIf="openProgramsNav">&#60;</span>
                <span class="d-flex align-self-center justify-content-center" *ngIf="!openProgramsNav">&#62;</span>
            </div>
        </div>
        <div class="program-reports" [ngClass]="{'col-sm-7': openProgramsNav&&this.reportsRouter, 'col-sm-6': openProgramsNav&&!this.reportsRouter, 'col-sm-11': !openProgramsNav}">
            <div class="programs-header d-flex align-self-center justify-content-center">{{ this.authservice.getProgramFullName(this.getProgram()) }}</div>
            <ul class="nav-list" *ngIf="localStorage.get('user_type')=='Plan'">
                <li class="nav-list-item report-item"  *ngFor="let reportName of this.getReportNames()">
                    <div>
                        <img src="../../../assets/{{reportName}}.png" alt="u_card"  class="program-icon"> 
                        <span>
                            <a routerLink="/dashboard" 
                                [queryParams]="{program: this.getProgram(), report: reportName, reportRoleType: 'Plan'}"
                            >{{ reportName }}</a>
                        </span>
                    </div>
                    <!-- <div class="plan-provider">
                        <span *ngFor="let role of this.getReportRoles(reportName)">
                            <a routerLink="/dashboard" 
                                [queryParams]="{program: this.getProgram(), report: reportName}"
                            >{{ role }}</a>
                        </span>
                    </div> -->
                </li>
            </ul>
            <ul class="nav-list" *ngIf="localStorage.get('user_type')=='Provider'">
                <li class="nav-list-item report-item"  *ngFor="let reportName of this.getReportNames()">
                    <div>
                        <img src="../../../assets/{{reportName}}.png" alt="u_card"  class="program-icon"> 
                        <span>
                            <a routerLink="/dashboard" 
                                [queryParams]="{program: this.getProgram(), report: reportName, reportRoleType: 'Provider'}"
                            >{{ reportName }}</a>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
