import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-patient-centered-medical-home',
  templateUrl: './patient-centered-medical-home.component.html',
  styleUrls: ['./patient-centered-medical-home.component.css']
})
export class PatientCenteredMedicalHomeComponent implements OnInit {

  constructor(public authservice: AuthorizationService, private http: HttpClient, public dashboard: DashboardComponent, public localStorage:LocalStorageService) { }

  ngOnInit(): void {
  }

}
