<div class="container-fluid terms-main">
    <div class="row" style="margin-bottom: 1rem;">
        <div class="col-sm-12" style="margin-top: 1rem;">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" style="text-decoration:None;">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Privacy</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <h5 style="color: #333;">Terms of Use</h5>
            <p style="padding-left: 25px;">
                <a href="https://www.uhc.com/privacy/terms-of-use" target="_blank">
                    Terms of use - Golden Rule
                </a>
            </p>
        </div>
        <div class="col-sm-8">
            <h2>Terms of Use</h2>
            <p>If you believe you are experiencing a medical emergency, please call 911.</p>

            <h5>Agreement and Terms</h5>
            <p>These Terms of Use ("Terms") describe the rules for using UnitedHealthcare (also "'we,"" "us,"" "our,"
                and "Company")
                and Affiliates' (an entity controlling, controlled by, or under common control with a named party)
                online and mobile
                websites, platforms, services and applications ("Online Services"). </p>

            <p>Our Online Services are intended for a United States audience. If you live outside the U.S., you may see
                Content on
                the Online Services about products or therapies that are not available or authorized in your country.
            </p>

            <p>By using our Online Services, you agree to these Terms and our Privacy Policies. We may change these
                Terms at any
                time, and such changes will be posted on the Online Services, with the date of the last revision listed
                as the
                "Effective Date" at the bottom of these Terms. Any modifications will be effective immediately upon such
                posting. By
                continuing to use the Online Services, you consent to any changes to our Terms. </p>

            <p>Your affirmative act of using the Online Services, or registering for membership, constitutes your
                electronic
                signature to these Terms and your consent to enter into this agreement electronically. You may print and
                retain a
                copy of these Terms. To print, you will need (i) a device (such as a computer or mobile phone) with a
                web browser
                and Internet access and (ii) either a printer or storage space on such device.</p>

            <h5>Important Note Regarding Content of Digital Property</h5>
            <p>The information and content (collectively, "Content") on the Online Services is for general educational
                information
                only. We are not a health care provider. We do not recommend any health care service, supply, or
                treatment for you.
                You should call your health care provider if you have questions, at any time, about your health.</p>

            <p>he Content may include information regarding therapeutic and generic alternatives for certain
                prescription drugs and
                may describe uses for products or therapies that have not been approved by the Food and Drug
                Administration.</p>

            <p>The Content should not be considered financial advices, legal advice or tax advice.</p>

            <p>You are responsible for protecting your username (e.g., the email address and password that you provide
                when
                registering for Online Services) or other activation codes, and if they are compromised, you agree to
                change your
                username and password and immediately Contact Us.</p>

            <h5>Restrictions on Use of Online Services</h5>
            <h5>You Will Not:</h5>
            <ul>
                <li>Use our Online Services or Content in any way not expressly permitted by these Terms;</li>
                <li>Copy, modify or harvest data, Content, or materials from the Online Services;</li>
                <li>Remove or alter, any copyright or other proprietary rights or notices on the Online Services;</li>
                <li>Misrepresent your identity or provide any false information;</li>
                <li>Interfere with the operation of our Online Services;</li>
                <li>Share any password with any third parties or use any third-party’s password;</li>
                <li>Engage in commercial, competitive, or viral messaging, or sending of unsolicited advertisements, or
                    similar
                    communications, including harmful computer code, viruses, or malware;</li>
                <li>Use the Online Services in any way that could, in our sole judgment, interfere with any other
                    party's use or
                    enjoyment of the Online Services, impair our networks or servers, or expose us or any third party to
                    any claims
                    or liability whatsoever, or use software or other means to access, "scrape," "crawl," or "spider,"
                    any webpages
                    or other services from the Online Services. If you are blocked from the Online Services (including
                    by blocking
                    your IP address), you agree not to implement any measures to circumvent such blocking;</li>
                <li>Directly or indirectly authorize anyone else to take actions prohibited in this section; or</li>
                <li>Attempt to reverse engineer any of the software used to provide the Online Services.</li>
            </ul>
            <h5>You Agree That:</h5>
            <ul>
                <li>You will comply with all applicable laws and regulations;</li>
                <li>You represent and warrant that you are at least 13 years of age, and that, if you are between 13 and
                    the age of
                    majority in your state and otherwise not emancipated, a parent and/or guardian agrees to these Terms
                    on your
                    behalf; and</li>
                <li>We may take any measures we deem appropriate, in our sole discretion and permitted by law, to
                    enforce these
                    Terms. </li>
            </ul>

            <h5>Posting Messages, Comments or Content</h5>
            <h5>You Will Not:</h5>
            <ul>
                <li>Post any improper or inappropriate content, including that which is obscene, libelous, harassing,
                    threatening,
                    harmful, inflammatory, invasive of privacy, or otherwise objectionable, constitutes advertising or
                    solicitation,
                    or violates applicable law.</li>
            </ul>
            <h5>You Agree That:</h5>
            <ul>
                <li> content posted to the Online Services by third parties is only the opinion of the poster, is no
                    substitute for
                    your own research, and should not be relied upon for any purpose;</li>
                <li>You are solely responsible for the content of any postings that you submit. Any content you submit
                    will be
                    routed through the Internet and you understand and acknowledge that you have no expectation of
                    privacy regarding
                    that content. Never assume that you are anonymous and cannot be identified by your posts;</li>
                <li>If you do post content or submit material to the Online Services, and unless we indicate otherwise,
                    you grant
                    the Company a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to
                    use,
                    reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display
                    such content
                    throughout the world in any media;</li>
                <li>You grant the Company and any of its sublicensees the right to use the name that you submit in
                    connection with
                    such content, if they choose; and</li>
                <li>You represent and warrant that you own or otherwise control all of the rights to the content that
                    you post; that
                    the content is accurate; that use of the content you supply does not violate these Terms and will
                    not cause
                    injury to any person or entity; and that you will indemnify the Company or its affiliates for all
                    claims
                    resulting from any content that you supply. </li>
            </ul>

            <h5>License to Use Online Services and Content Ownership</h5>
            <p>Subject to these Terms, the Company grants you a personal, non-commercial, non-transferable,
                non-exclusive,
                revocable, limited license to view the Content on our Online Services for the sole purpose of obtaining
                information
                regarding our plans or products and related services. All rights, title, and interest in and to the
                Online Services,
                including the Content, and all intellectual property rights, including all copyright, trademark, patent,
                and trade
                secret rights will remain with the Company and our licensors and vendors. No ownership interest is
                transferred to
                you or any other entity by virtue of making the Content available on the Online Services, granting you a
                license to
                use Online Service, or your entering into this Agreement.</p>

            <p>We may terminate this license at any time for any reason. If you breach any of these Terms, your license
                to the
                Online Services and its Content terminates immediately. Upon the termination of this license, you must
                stop using
                the Online Services, including all Content, and return or destroy all copies, including electronic
                copies, of the
                Content in your possession or control.</p>

            <h5>Copyright Infringement - DMCA Notice</h5>
            The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright owners who believe
            that
            material appearing on the Internet infringes their rights under US copyright law. If you believe, in good
            faith, that
            content or material on our Online Services infringes a copyright owned by you, you (or your agent) may send
            the Company
            a notice requesting that the material be removed or access to it blocked. Notices and counter-notices with
            respect to
            the Online Services should be sent to the applicable address below:

            <table cellpadding="1" cellspacing="0" border="1" width="100%" style="table-layout: fixed; border: 0;">
                <!-- <caption class="sr-only">Contact information</caption> -->
                <tbody>
                    <tr>
                        <th scope="col" style="border-right: 1px solid #b3babc; border-bottom: 1px solid #b3babc;">
                            Company</th>
                        <th scope="col" style="border-left: 1px solid #b3babc; border-bottom: 1px solid #b3babc;">
                            Contact
                            Information</th>
                    </tr>
                    <tr>
                        <td style="border-right: 1px solid #b3babc; border-top: 1px solid #b3babc;">UnitedHealthcare
                        </td>
                        <td style="border-left: 1px solid #b3babc; border-top: 1px solid #b3babc;">
                            <p>Attn: DMCA Registered Agent<br>
                                9900 Bren Road East<br>
                                Minnetonka, MN 55343</p>
                            <p><a href="mailto:DMCARegisteredAgent@uhc.com">DMCARegisteredAgent@uhc.com</a></p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>The notice must include the following information: (a) a physical or electronic signature of a person
                authorized to
                act on behalf of the owner of an exclusive right that is allegedly infringed; (b) identification of the
                copyrighted
                work claimed to have been infringed; (c) identification of the material that is claimed to be infringing
                or the
                subject of infringing activity; (d) the name, address, telephone number, and email address of the
                complaining party;
                (e) a statement that the complaining party has a good-faith belief that use of the material in the
                manner complained
                of is not authorized by the copyright owner, its agent or the law; and (f) a statement that the
                information in the
                notification is accurate and, under penalty of perjury, that the complaining party is authorized to act
                on behalf of
                the owner of an exclusive right that is allegedly infringed. If you believe in good faith that a notice
                of copyright
                infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice.
                Notices and
                counter-notices must meet the then-current statutory requirements imposed by the DMCA.</p>

            <h5>Unsolicited Idea Submission Policy</h5>
            <p>It is our policy not to accept or consider unsolicited ideas or offers to sell intellectual property. We
                ask that you
                please do not submit any such ideas or offers to us or our employees or contractors.</p>

            <p>This policy is intended to avoid potential misunderstandings if our technology, products, or services may
                seem
                similar to a submission made to the company. If you still choose to make any submission to us, you agree
                as follows,
                regardless of any statements made in your submission that:</p>

            <ul>
                <li>We owe you no compensation;</li>
                <li>There is no duty of confidentiality between us and you;</li>
                <li>Your submission automatically becomes our property, and we may use or redistribute the contents of
                    that
                    submission in any way for any purpose;</li>
                <li>We are free to acquire, develop, and sell services and products that may be competitive to those you
                    offer or
                    suggest; and</li>
                <li>It is your responsibility to protect your own intellectual property; you should not make a
                    submission to us if
                    you have concerns about intellectual property.</li>
            </ul>
            <p>If you are uncertain about the meaning of this policy or the legal ramifications of submitting materials
                to us, you
                should consult with your attorney before making a submission.</p>

            <h5>Links</h5>
            <p>While visiting our Online Services, you may go to a link to other online websites, mobile websites,
                platforms,
                services, and applications ("Weblinks") and leave our Online Services. For your convenience, we provide
                Weblinks to
                other online content or sites that may contain information that may be useful or interesting to you. We
                do not
                endorse, nor are responsible for, the content, accuracy or accessibility of the content of Weblinks
                operated by
                third parties. You are solely responsible for your dealings with such third parties and should review
                the Terms and
                privacy policies of such third parties.</p>

            <h5>No Warranty</h5>
            <p>Nothing within these Terms should be meant or implied to be a warranty. You use the Online Services at
                your own risk.
                We do not guarantee the accuracy, completeness, timeliness, correctness or reliability of any content
                available
                through the Online Services. Online Services are provided to you when available and are provided on an
                "as is"
                basis. We make no representation that use of our Online Services will be uninterrupted or error-free, or
                free of
                viruses or other harmful components.</p>

            <p>Some states do not allow a Terms of Use to have a "no-warranties" provision, and even though these Terms
                make no
                warranty, if your state does not allow this type of limitation, this paragraph will not apply to you.
            </p>

            <h5>Limitation of Liability</h5>
            <p>You agree that we have no liability for any loss arising out of, or relating to: these Online Services;
                any
                third-party site or program accessed through the Online Services; Any acts or omissions by us or any
                third party;
                and/or your access or use of the Online Services. This limitation of liability includes any claim based
                on warranty,
                contract, tort, strict liability, or any other legal theory.</p>

            <p>This limitation of liability does not apply if you are a New Jersey resident. With respect to residents
                of New
                Jersey, any released parties are not liable for any damages unless such damages are the result of our
                negligent or
                reckless acts or omissions; and any released parties are not, in any case, liable for indirect,
                incidental, special,
                consequential or punitive damages.</p>

            <p>Other states may also limit liability. If your state does not allow this type of limitation, one or more
                of the
                limitations above may not apply to you. </p>

            <h5>Indemnification</h5>
            <p>You agree to defend, indemnify, and hold harmless any released parties from any claim by third parties,
                including
                reasonable attorneys' fees for counsel of our own choosing, arising out of or related to your breach of
                the Terms or
                any copyright infringement, misappropriation, misuse, gross negligence, intentional misconduct, or
                violation of
                applicable law relating to your use of the Online Services or Content. You may not transfer or assign
                any rights or
                obligations under this Agreement. In any litigation, you will cooperate with us in asserting any
                available defenses.
            </p>

            <h5>Export Controls</h5>
            <p>You may not use, export, re-export, import, sell, transfer, or proxy our Online Services or Content
                unless such
                activity is permitted by these Terms and such activity is not prohibited by United States law, the laws
                of the
                jurisdiction in which you receive our Online Services, or any other applicable laws and regulations. In
                particular,
                but without limitation, the Online Services and/or Content may not be exported, re-exported or made
                available in any
                manner (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of
                Specially
                Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List.</p>

            <p>By using our Online Services, you represent and warrant that you and any ultimate beneficiary of our
                Online Services
                are not located in any such country or on any such list. You also agree that you will not use our Online
                Service for
                any purposes prohibited by U.S. law, including, without limitation, the development, design, manufacture
                or
                production of nuclear missiles or chemical or biological weapons.</p>

            <h5>Governing Law and Statute of Limitations</h5>
            <p>You agree that Minnesota law governs these Terms and any claim or dispute that you may have against us,
                and you agree
                to the jurisdiction and venue of the state and federal courts in Minnesota for any dispute involving the
                Company or
                its employees, officers, directors, agents and providers.</p>

            <p>Unless you are a resident of New Jersey, you must initiate any cause of action within one year after the
                claim has
                arisen, or you will be barred from pursuing any cause of action. The United Nations Convention on
                Contracts for the
                International Sale of Goods do not apply to these Terms.</p>

            <h5>Termination</h5>
            <p>We may cancel, suspend or block your use of the Online Services and/or registration at any time, without
                cause and/or
                without notice. You agree that we will not be liable to you or any other party for any termination of
                your access to
                the Online Services in accordance with these Terms.</p>

            <h5>Severability</h5>
            <p>If any provision of these Terms is held to be unenforceable or invalid by an arbitrator or court of
                competent
                jurisdiction, the remaining portions of the Terms will be determined without the unenforceable or
                invalid provision.
                All other Terms will remain in full force and effect.</p>

            <h5>Miscellaneous</h5>
            <p>These Terms, and any supplemental terms, policies, rules and guidelines posted on the Online Services,
                constitute the
                entire agreement between you and us in connection with the Online Services and supersede all previous
                written or
                oral agreements. You acknowledge that we have the right to seek, at your expense, an injunction to stop
                or prevent a
                breach of your obligations. No waiver by us will have effect unless such waiver is set forth in writing
                and signed
                by us; nor will any such waiver of any breach or default constitute a waiver of any subsequent breach or
                default.
            </p>

            <h5>Mobile Devices and Applications</h5>
            <p>The following additional terms apply to your access to or use of any mobile application in connection
                with the Online
                Services (together with all information and software associated therewith, the "Application" or
                "Applications")
                through any mobile device (such as tablets, mobile phones, etc.), and are "Additional Terms" as defined
                above.</p>

            <p><span class="inline-h6"><strong>Rights Granted to You.</strong></span> We grant you a limited, non-exclusive, revocable, non-transferrable license to
                download,
                install and use the Applications solely for your personal, non-commercial use on a mobile or tablet
                device owned or
                controlled by you and only in accordance with the Terms. Other than the limited rights granted in the
                immediately
                preceding sentence, no other rights are granted to you. This is only a license, and not a sale of, the
                Applications
                to you.</p>

            <p><span class="inline-h6"><strong>Additional Restrictions and Notices.</strong></span> You agree that you will not remove, disable, circumvent or otherwise
                create or
                implement any workaround to any copy protection, rights management, or security features in or
                protecting the
                Applications. You acknowledge that we may issue an upgraded version of the Applications and may
                automatically
                upgrade the Application that you are using. You consent to such automatic upgrading and agree that these
                Terms shall
                govern all such upgrades. Our Applications or the Online Services may include third-party code and other
                software,
                which is governed by the applicable open source or third-party end user license agreement, if any, that
                authorizes
                the use of such code.</p>

            <p><span class="inline-h6"><strong>Third-Party Terms.</strong></span> You agree to comply with all applicable terms, conditions and agreements between you
                and any third
                party that provides products or services that facilitate or enable your use of any Application, and you
                acknowledge
                and agree that your use of any Application may result in charges to you by those third parties in
                connection with
                the products and services they provide to you (such as data plan charges), and you will be solely
                responsible for
                any such charges.</p>

            <p><span class="inline-h6"><strong>Termination of Your Rights.</strong></span> Upon any termination of your rights hereunder, for any reason, you will
                immediately
                uninstall or delete the Applications and cease any further use of such Applications.</p>

            <p><span class="inline-h6"><strong>Special Notice for International Use/Export Controls.</strong></span> Any technology or software underlying the
                Applications or
                Online Services that is available in connection with the provision of the Online Services and the
                transmission of
                applicable data (collectively, the "Software"), if any, is subject to United States export controls. No
                Software may
                be downloaded from the Applications or Online Services or otherwise exported or re-exported in violation
                of U.S.
                export laws. Downloading or using the Software is at your sole risk. Recognizing the global nature of
                the Internet,
                you agree to comply with all local rules and laws regarding your use of the Applications and/or Online
                Services,
                including as it concerns online conduct and acceptable content.</p>

            <p><span class="inline-h6"><strong>Apple iOS App.</strong></span> If the Online Services that you use include an Application that you download, access
                and/or use and
                that runs on Apple's iOS operating system (an "iOS App"), you acknowledge and agree that:</p>
            <ul>
                <li>The iOS App may only be accessed and used on a device owned or controlled by you and using Apple's
                    iOS operating
                    system;</li>
                <li>These Terms are between you and us, and not with Apple;</li>
                <li>Apple has no obligation at all to provide any support or maintenance services in relation to the iOS
                    App, and if
                    you have any maintenance or support questions in relation to the iOS App, please contact Company,
                    not Apple;
                </li>
                <li>Except as otherwise expressly set forth in these Terms, any claims relating to the possession or use
                    of the iOS
                    App are between you and us (and not between you, or anyone else, and Apple);</li>
                <li>In the event of any claim by a third party that your possession or use (in accordance with these
                    Terms) of the
                    iOS App infringes any intellectual property rights, Apple will not be responsible or liable to you
                    in relation
                    to that claim; and</li>
                <li>Although these Terms are entered into between you and Company (and not Apple), Apple, as a
                    third-party
                    beneficiary under these Terms, will have the right to enforce these terms against you.</li>
                <li>In addition, you represent and warrant that:</li>
                <ul>
                    <li>You are not, and will not be, located in any country that is the subject of a United States
                        Government
                        embargo or that has been designated by the United States Government as a "terrorist supporting"
                        country;
                    </li>
                    <li>You are not listed on any United States Government list of prohibited or restricted parties; and
                    </li>
                    <li>If the iOS App does not conform to any warranty applying to it, you may notify Apple, which will
                        then refund
                        the purchase price of the iOS App (if any) to you. Subject to that, and to the maximum extent
                        permitted by
                        law, Apple does not give or enter into any warranty, condition or other term in relation to the
                        iOS App and
                        will not be liable to you for any claims, losses, costs or expenses of whatever nature in
                        relation to the
                        iOS App or as a result of you or anyone else using the iOS App or relying on any of its content.
                    </li>
                </ul>
            </ul>

            <p><span class="inline-h6"><strong>Google App.</strong></span> If the Online Services that you use includes an Application that you download, access, and/or
                use from
                the Google Play Store ("Google-Sourced Software"): (i) you acknowledge that these Terms are between you
                and us only,
                and not with Google, Inc. ("Google"); (ii) your use of Google-Sourced Software must comply with Google’s
                then-current Google Play Store Terms of Service; (iii) Google is only a provider of the Google Play
                Store where you
                obtained the Google-Sourced Software; (iv) we, and not Google, are solely responsible for our
                Google-Sourced
                Software; (v) Google has no obligation or liability to you with respect to Google-Sourced Software or
                the Terms; and
                (vi) you acknowledge and agree that Google is a third-party beneficiary to the Terms as it relates to
                our
                Google-Sourced Software.</p>

            <h5>Survival and Assignment</h5>
            <p>Your obligations under the following sections survive termination of this Agreement: Agreement and Terms;
                Important
                Note Regarding Content of Digital Property; License to Use the Online Services and Content Ownership;
                Restrictions
                on Use of Online Services; Posting Messages, Comments or Content; Copyright Infringement – DMCA Notice;
                Unsolicited
                Idea Submission Policy; No Warranty; Limitation of Liability; Indemnification; Export Controls;
                Governing Law and
                Statute of Limitations; Termination; Severability; Miscellaneous and Mobile Devices and Applications.
                You may not
                transfer or assign any rights or obligations under this Agreement. The Company may transfer or assign
                its rights and
                obligations under this Agreement.</p>

            <h5>Contact Us</h5>
            <p>If you have questions regarding these Terms, contact us at: </p>

            <p>Customer Service - Privacy Unit</p>
            <p>PO Box 740815</p>
            <p>Atlanta, GA 30374-0815</p>

            <h5>Effective Date</h5>
            <p>December 21, 2022</p>
        </div>
    </div>
</div>
