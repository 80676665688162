<!-- <footer class="bg-light fixed-bottom" id="footer"> -->
    <!-- <div class="row footer-separator"></div> -->
    <div class="container-fluid p-1 dark-blue-color navbar-brand">
        <div class="row">
<!--        <div class="col-sm-4 d-flex justify-content-start">
                <li class=" text-left text-white" style="list-style: none; padding-left: 24px;">© 2023 UnitedHealth Care | All Rights Reserved</li>
            </div>

            <div class="col-sm-4 d-flex justify-content-center">
                <a class="text-center text-white" routerLink="contact-us">Contact Us</a>
            </div>
            <div class="col-sm-4 d-flex justify-content-end text-white" style="padding-right: 24px;">
                <a class="text-lg-end text-white" routerLink="privacy">Privacy</a>&NonBreakingSpace;|&NonBreakingSpace;<a class="text-lg-end text-white" routerLink="terms-of-use">Terms of Use</a>
            </div>  --> 
            <div class="col-sm-4 d-flex justify-content-start">
                <li class=" text-left text-white" style="list-style: none; padding-left: 24px;"></li>
            </div>

            <div class="col-sm-4 d-flex justify-content-center">
                <a class="text-center text-white">Contact Us</a>
            </div>
            <div class="col-sm-4 d-flex justify-content-end text-white" style="padding-right: 24px;">
                <a class="text-lg-end text-white">Privacy</a>&NonBreakingSpace;|&NonBreakingSpace;<a class="text-lg-end text-white" >Terms of Use</a>
            </div>
        </div>
    </div>
<!-- </footer> -->