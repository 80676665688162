<nav class="navbar navbar-expand-lg navbar-light">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="nav navbar-nav mr-auto">
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle" [routerLink]="" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle (click)="this.openExportMenu()">
                    Export
                </a>
                <div id="navbarDropdownMenu" class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
                    <li class="dropdown-item" ngbDropdownItem (click)="this.exportReportPDF()"><a [routerLink]="" id="navbarDropdown">PDF</a></li>
                    <li ngbDropdownItem><div class="dropdown-divider"></div></li>
                    <li class="dropdown-item" ngbDropdownItem (click)="this.exportReportPPTX()"><a [routerLink]="" id="navbarDropdown">PowerPoint</a></li>
                </div>
            </li>
        </ul>
        <ul class="navbar-nav ms-auto">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="" (click)="this.toggleReportFullscreen()">
                    <img src="../../../assets/fullscreen-white-alternate.png" alt="" width="30" height="24" class="d-inline-block align-text-top">
                    <!-- FullScreen -->
                </a>
            </li>
        </ul>
    </div>
</nav>
