import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  menuType: String = 'default';
  loggedIn = false;
  constructor(public authservice: AuthorizationService, public localStorage:LocalStorageService, private route: Router) { }

  ngOnInit() {
    
  }

  ngDoCheck(){
    this.loggedIn = this.authservice.isUserLoggedIn();
    // this.route.events.subscribe((val:any)=>{
    //   if(val.url){
    //     console.log(localStorage.getItem('data.access_token'))
    //     if(localStorage.getItem('data.access_token')){
    //       this.menuType = 'loggedin'
    //       console.log('loggedin');
    //     }else{
    //       this.menuType = 'default'
    //       console.log('default');
    //     }
    //   } 
    // })
  }
}
