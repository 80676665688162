import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
  })
export class HttpResponseCheck {
    private toastrConfig = {
        closeButton: true,
        timeOut: 10000,
        extendedTimeOut: 5000,
        progressBar: true
    }

    constructor(private toastr: ToastrService, private router: Router){
    }

    public NotifyError(status?: any){
        console.log("NotifyError: " + status);
        this.toastr.error('Something went wrong', 'Error', this.toastrConfig);
        this.router.navigateByUrl("/contact-us");
    }

    public CheckHttpResponseNotify(response: HttpResponse<Object>) {
        // response;
        if(!response.ok){
            this.NotifyError();
        }
    }
}
