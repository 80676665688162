<div class="modal fade" id="sessionTimeoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="sessionTimeoutModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sessionTimeoutModalLabel">Session Timeout</h5>
                <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
            </div>
            <div class="modal-body">
                The user session is about to end. Do you want to stay logged in?
            </div>
            <div id="warningTimerParent">
                <span id="warningTimer">&NonBreakingSpace;</span>
            </div>
            <div class="modal-footer">
                <button id="modal-btn-no" type="button" class="btn btn-secondary">No</button>
                <button id="modal-btn-yes" type="button" class="btn btn-primary">Yes</button>
            </div>
        </div>
    </div>
</div>
