import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  static accessToken = '';
  private count = 0;
  constructor(private readonly injector: Injector) {
  }

  get spinner(): NgxSpinnerService {
    return this.injector.get(NgxSpinnerService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(
      request.url.includes(environment.powerBiExportURL)
      || request.url.includes(environment.powerBiExportStatusURL)
      || request.url.includes(environment.powerBiExportFileURL)
    ){
      return next.handle(request);
    }
    else{
      this.spinner.show();
      this.count++;
      return next.handle(request).pipe(catchError((error: any) => {
        return throwError(error);
      }),finalize( () => {
        this.count--;
        if (this.count === 0) {
          this.spinner.hide();
        }
      })
      );
    }
  }
}
