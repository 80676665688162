<div class="d-flex flex-column body">
    <ngx-spinner bdColor="rgba(51,51,51,0.9)" 
                size="medium" 
                color="#002060" 
                type="ball-clip-rotate" 
                [fullScreen]="true">
        <p style="font-size: 15px; color: white"> Loading....</p>
    </ngx-spinner>
    <header>
        <app-header></app-header>
    </header>
    <main>
        <router-outlet></router-outlet>
    </main>
    <footer>
        <app-footer></app-footer>
    </footer>
    <app-session-timeout></app-session-timeout>
</div>
