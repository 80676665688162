import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  loggedIn = false;

  constructor(public authservice: AuthorizationService) { 
    
  }

  ngOnInit(): void {
  }

  ngDoCheck(){
    this.loggedIn = this.authservice.isUserLoggedIn();
  }

}
