import { Component, OnInit } from '@angular/core';
import { PowerbiService } from 'src/app/services/powerbi-service/powerbi.service';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
const domToPdf = require('dom-to-pdf');

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.css']
})
export class ActionBarComponent implements OnInit {

  constructor(private powerBiService: PowerbiService, private dashboardComponent: DashboardComponent) { }

  ngOnInit(): void {
  }
  
  toggleReportFullscreen(){
    this.powerBiService.toggleFullScreen();
  }

  openExportMenu (){
    document.getElementById("navbarDropdownMenu")?.classList.add("show");
    document.getElementById("navbarDropdown")?.setAttribute("aria-expanded", "true");
    document.getElementById("navbarDropdown")?.classList.add("show");
  }

  exportReportPDF(){
    document.getElementById("navbarDropdownMenu")?.classList.remove("show");
    this.powerBiService.exportReportToFile(
      this.dashboardComponent.getUrlName(),
      this.dashboardComponent.getProgram(),
      this.dashboardComponent.getUserType(),
      this.dashboardComponent.getTinNumber(),
      this.powerBiService.ExportTypes.PDF
    );
  }

  exportReportPPTX(){
    document.getElementById("navbarDropdownMenu")?.classList.remove("show");
    this.powerBiService.exportReportToFile(
      this.dashboardComponent.getUrlName(),
      this.dashboardComponent.getProgram(),
      this.dashboardComponent.getUserType(),
      this.dashboardComponent.getTinNumber(),
      this.powerBiService.ExportTypes.PPTX
    );
  }

  exportCurrentReportPDF(){
    var element = document.getElementById('reportContainer');
    var options = {
      filename: 'test.pdf'
    };
    domToPdf(element, options, function() {
      console.log('done');
    });
  }

  exportCurrentReportPPTX(){
  }
}
