import { Component, HostListener } from '@angular/core';
//import { AuthService } from './auth/auth.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from 'src/app/services/authenticate-service/authorization.service';
import { SessionTimeoutComponent } from 'src/app/components/session-timeout/session-timeout.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'VBP Intelligence Hub';

  private userActivity!: NodeJS.Timeout;

  constructor(private authservice: AuthorizationService, public localStorage: LocalStorageService, private sessionTimeoutComponent: SessionTimeoutComponent) {
    // this.setTimeout();
    this.authservice.userActive.subscribe(() => {
      this.setTimeout();
    });
    this.authservice.userInactive.subscribe(() => {
      console.log('user has been inactive!!!!!!!!!!!!!!!!!!!!!!!')
      authservice.logout();
    });
  }

  setTimeout() {
    let expires_in = Number(localStorage.getItem('data.expires_in'));
    // let expires_in = 25;
    let warningTimeout = this.sessionTimeoutComponent.warningTimeout;
    this.userActivity = setTimeout(() => this.timeoutDialogue(), 1000 * (expires_in - warningTimeout - 10));
  }

  timeoutDialogue() {
    this.sessionTimeoutComponent.showWarning();
  }

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  refreshUserState() {
    if (this.authservice.isUserLoggedIn()) {
      !this.sessionTimeoutComponent.warningVisible && clearTimeout(this.userActivity);
      this.authservice.refreshToken();
      !this.sessionTimeoutComponent.warningVisible && this.setTimeout();
    }
  }
}
