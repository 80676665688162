<div class="container-fluid m-0">
    <div class="row ">
        <div class="col-sm-6" style="margin-top: 1rem; margin-left: 35%;">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" style="text-decoration:None;">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Health Link</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
        <app-programs class="col-sm-4"></app-programs>
        <div class="col-sm-8 program-reports">
            <div class="programs-header">Health Link Reports</div>
            <div class="row">
                <div class="col-sm-12">
                    <ul class="nav-list" *ngIf="localStorage.get('user_type')=='Plan'">
                        <li class="nav-list-item program-item">
                            <div>
                                <img src="../../../assets/picture_quality_gaps.png" alt="picture_emergency"  class="program-icon"> 
                                <span>Quality Gaps in Care Dashboard</span>
                            </div>
                            <div class="plan-provider">
                                <span><a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Gaps in Care Dashboard','HL','Plan')">Plan</a></span>
                                <span><a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Gaps in Care Dashboard','HL','Provider')">Provider</a></span>
                            </div>
                        </li>
                        <li class="nav-list-item program-item">
                            <div>
                                <img src="../../../assets/picture_quality.png" alt="picture_emergency"  class="program-icon"> 
                                <span>Quality Dashboard - Quarterly</span>
                            </div>
                            <div class="plan-provider">
                                <span><a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Dashboard - Quarterly','HL','Plan')">Plan</a></span>
                                <span><a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Dashboard - Quarterly','HL','Provider')">Provider</a></span>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav-list" *ngIf="localStorage.get('user_type')=='Provider'">
                        <li class="nav-list-item program-item">
                            <a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Gaps in Care Dashboard','HL','Provider')">
                                <img src="../../../assets/picture_quality_gaps.png" alt="picture_emergency"  class="program-icon"> 
                                <span>Quality Gaps in Care Dashboard</span>
                            </a>
                        </li>
                        <li class="nav-list-item program-item">
                            <a routerLink="/dashboard" (click)="this.dashboard.urlLinkName('Quality Dashboard - Quarterly','HL','Provider')">
                                <img src="../../../assets/picture_quality.png" alt="picture_emergency"  class="program-icon"> 
                                <span>Quality Dashboard - Quarterly</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="margin-top">
            <div class="reports-bottom-separator">
            </div>
        </div> -->
    </div>
</div>
