import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { PowerbiService } from 'src/app/services/powerbi-service/powerbi.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  private urlName = '';
  private program = '';
  private userType = '';
  private reportRoleType = '';
  private tinNumber = '';
  public openProgramsNav = true;
  public openReportsNav = true;
  public reportLoaded = false;
  public tabActive: string ="";

  constructor(private powerBiService: PowerbiService, 
    public authservice: AuthorizationService,
    public localStorage:LocalStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.powerBiService.removeReport();
      this.localStorage.set('reportLoaded', false);
      this.urlName = params['report'];
      this.program = params['program'];
      this.userType = this.localStorage.get('user_type');
      this.reportRoleType = params['reportRoleType'];
      this.tabActive=this.reportRoleType;
      this.tinNumber = this.localStorage.get('tinNumber');
      if(this.tabActive!==undefined){
        this.insertPowerBIReport();
      }
    })
  }
  
  public setTinNumber(tinNumber: string) {
    this.tinNumber = tinNumber;
  }

  getTinNumber() {
    return this.tinNumber;
  }

  public setUrlName(urlName: string) {
    this.urlName = urlName;
  }

  getUrlName() {
    return this.urlName;
  }
  
  public setProgram(program: string) {
    this.program = program;
  }

  getProgram() {
    return this.program;
  }

  public setReportRoleType(reportRoleType: string) {
    this.reportRoleType = reportRoleType;
  }

  getReportRoleType() {
    return this.reportRoleType;
  }
  
  public setuserType(userType: string) {
    this.userType = userType;
  }

  getUserType() {
    return this.userType;
  }

  urlLinkName(urlName: string, program: string, reportRoleType: string){
	  this.localStorage.set('urlName', urlName);
    this.localStorage.set('program', program);
    this.localStorage.set('reportRoleType', reportRoleType);
  }

  getBreadcrumbLink(){
    switch(this.localStorage.get('program')) {
      case "EC":
        return "episode-of-care";
      case "PCMH":
        return "patient-centered-medical-home";
      case "HL":
        return "health-link";
      case "TENNSTAR":
        return "tennstar";
      default:
        return "";
    }
  }

  async insertPowerBIReport() {
    await this.powerBiService.getPowerBiEmbedInfo(this.urlName, this.program, this.reportRoleType, this.tinNumber);
    this.powerBiService.showReport();
 }

  checkProgramPermisson(): boolean{
    var program: string = this.localStorage.get("program") || "null";
    var enrolled_programs: string[] = this.localStorage.get("enrolled_programs") || [];
    var user_type: string = this.localStorage.get("user_type") || "null"
    return enrolled_programs.includes(program) || user_type == "Plan";
  }

  goBackReport(){
    document.getElementsByTagName("iframe")[0].contentWindow!.history.back();
  }

  reportHome(){
    this.powerBiService.reloadReport();
  }

  toggleReportsNav(){
    this.openReportsNav = !this.openReportsNav;
  }

  planProviderTabToggle(roleToSwitch: string) {
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: {reportRoleType: roleToSwitch}, 
        queryParamsHandling: 'merge'
      });
  }
}
