<div class="container-fluid">
    <div class="row dark-blue-color header-height header-top justify-content-end">
        <div class="col-sm-3 align-self-center d-flex justify-content-end" *ngIf=!loggedIn>
            <nav class="navbar navbar-light">
                <a class="text-white" routerLink="new-user-and-user-access">New User & User Access</a>
            </nav>
        </div>
        <div class="col-sm-3 align-self-center d-flex justify-content-end" *ngIf=loggedIn>
            <nav class="navbar navbar-light">
                <a class="text-white" routerLink="user-guide">User Guides</a>
            </nav>
        </div>
        <div class="col-sm-3 align-self-center d-flex justify-content-end">
            <input type="text" class="form-control align-self-center input-width search-box" placeholder="What can we help you find?">
            <button class="btn" type="button">
                <img src="../../../assets/glass.png" alt="" class="search-glass">
            </button>
        </div>
    </div>
    <div class="row header-bottom">
       <!-- <div class="col-sm-3 header-img-outer">
            <img src="../../../assets/UHC_Lockup_blu_RGB.png" alt="" class="header-img">
        </div> -->
        <div class="col-sm-3 header-img-outer">
            <img src="../../../assets/optum_wht_bg.png" alt="" class="header-img">
        </div>
        <!-- <div class="col-sm-3 header-img-outer">
            <img src="" alt="" class="header-img">
        </div> -->
        <div class="col-sm-5 align-self-center d-flex justify-content-center header-middle-title">
             Episodes and Specialized Populations (ESP)
        </div>
        <div class="col-sm-4 align-self-center d-flex justify-content-end">
            <div class="align-self-center d-flex justify-content-end" *ngIf=!loggedIn>
                <button  type="button" class="btn btn-primary btn-lg round color-white"
                                (click)="authservice.goToLoginPage()">Sign In <span id="sign-in-arrow">&#8964;</span></button>
            </div>
            <div class="align-self-center d-flex " *ngIf=loggedIn>
                <button type="button" class="btn btn-primary btn-lg round color-white"
                                (click)="authservice.logout()">Logout</button> 
            </div>
            <div class="align-self-center d-flex justify-content-end username" *ngIf=loggedIn>
                {{localStorage.get('username')}}
            </div>   
        </div>
    </div>
</div>
