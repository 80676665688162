<div class="container-fluid new-user-container">
    <div class="row ">
        <div class="col-sm-12" style="margin-top: 1rem;">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" style="text-decoration:None;">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">VBP portal registration</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row new-user-top" style="margin-top: 2rem;">
        <div class="col-sm-6">
            <p class="align-self-center d-flex new-user-heading">Welcome new Users</p>
            <p>The UnitedHeathCare VBP Portal is the secure place to access interactive reports associated with
                value-based payment programs</p>
            <p>For Support with navigating and using this site,</p>
            <p>Email:&NonBreakingSpace;<a href="mailto:vbpintelligencehubsolutions@optum.com">vbpintelligencehubsolutions@optum.com</a></p>
        </div>
        <div class="col-sm-6" style="background-color: #ededed; border: 2px solid #bfbfbf;float: right;margin-left: auto;">
            <div class="row">
                <div class="col-sm-10">
                    <p class="align-self-center d-flex new-user-heading p-3">What you'll need</p>
                    <ul style="margin-top: -4vh;">
                        <li>A <a class="new-user-link" (click)="authservice.goToLoginPage()">One HealthCare Id</a></li>
                        <li class="">Organization Tax Identification Number (TIN)</li>
                        <li class="">Clinicians and physicians National Provider Identifier (NPI)</li>
                    </ul>
                </div>
                <div class="col-sm-2">
                    <img src="../../../assets/clipboard.png" alt="clipborad" style="height: 3rem; margin-top: 1rem;">
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="background-color: #ccf2f7; margin-top: 2rem;">
        <div class="col-sm-12">
            <div>
                <p class="align-self-center d-flex justify-content-center new-user-row">Access in 4 easy steps</p>
            </div>
            <div class="p-5 d-flex align-items-center d-flex justify-content-center">
                <div class="fa-stack fa-2x text-center">
                    <img src="../../../assets/1circles-overlay.png" alt="1"
                    style="height: 8vh; margin-left: 2.5rem; margin-top: -0.5rem;">
                </div>
                <div class="line-btw"></div>
                <div class="fa-stack fa-2x text-center">
                    <img src="../../../assets/2circles-overlay.png" alt="2"
                    style="height: 8vh; margin-left: 0rem; margin-top: -0.5rem;">
                </div>
                <div class="line-btw"></div>
                <div class="fa-stack fa-2x text-center">
                    <img src="../../../assets/3circles-overlay.png" alt="3"
                    style="height: 8vh; margin-left: -1.5rem; margin-top: -0.5rem;">
                </div>
                <div class="line-btw"></div>
                <div class="fa-stack fa-2x text-center">
                    <img src="../../../assets/4circles-overlay.png" alt="4"
                    style="height: 8vh; margin-left: -3rem; margin-top: -0.5rem;">
                </div>
            </div>
        </div>
        <div class="col-sm-3 text-center">
            <p class="content-heading">Create one Heathcare Id</p>
            <p class="content-text">Create a One HeathCare Id and register your secure access</p>
        </div>
        <div class="col-sm-3 text-center">
            <p class="content-heading">Sign In</p>
            <p class="content-text">Login to complete tasks and manage your account</p>
        </div>
        <div class="col-sm-3 text-center">
            <p class="content-heading">Connect your TIN</p>
            <p class="content-text">Connect organizational Tins and set initial settings</p>
        </div>
        <div class="col-sm-3 text-center">
            <p class="content-heading">Ready to use the portal</p>
            <p class="content-text">Easy to use self-paced educational tools User Guides</p>
        </div>
    </div>
</div>