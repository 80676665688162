import { Component, OnInit } from '@angular/core';
import * as bootstrap from 'bootstrap';
import { AuthorizationService } from 'src/app/services/authenticate-service/authorization.service';

// @Directive({selector: '#sessionTimeoutModal'})
// export class modal {
// }

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.css']
})
export class SessionTimeoutComponent implements OnInit {

  private sessionTimeoutWarnTimeout!: NodeJS.Timeout;
  private timerInterval!: NodeJS.Timeout;
  public warningVisible: boolean = false;
  public warningTimeout: number = 90;
  // public warningTimeout: number = 10;
  private myModal!: bootstrap.Modal;

  constructor(private authservice: AuthorizationService) {
    
  }

  ngOnInit(): void {
  }

  // @ViewChild(modal)
  showWarning() {
    let sessionTimeoutModal = document.getElementById('sessionTimeoutModal')!;
    let warningTimer = document.getElementById('warningTimer')!;
    warningTimer.innerHTML = "&NonBreakingSpace;";
    this.myModal = new bootstrap.Modal(sessionTimeoutModal!);
    this.myModal.show();
    this.warningVisible = true;
    this.setTimeout();
    console.log("showWarning");

    this.startTimer(this.warningTimeout, warningTimer);

    document.getElementById('modal-btn-no')!.onclick = () => {
      this.dontStayLoggedIn();
      this.hideWarning();
    }
    document.getElementById('modal-btn-yes')!.onclick = () => {
      this.stayLoggedIn();
      this.hideWarning();
    }
  }

  hideWarning() {
    this.myModal.hide();
    this.warningVisible = false;
    console.log("hideWarning");
  }

  stayLoggedIn() {
    this.authservice.userActive.next(true);
    clearTimeout(this.sessionTimeoutWarnTimeout);
    clearInterval(this.timerInterval);
  }

  dontStayLoggedIn() {
    this.authservice.userInactive.next(undefined);
    clearTimeout(this.sessionTimeoutWarnTimeout);
    clearInterval(this.timerInterval);
  }

  setTimeout() {
    this.sessionTimeoutWarnTimeout = setTimeout(() => {
      this.dontStayLoggedIn();
      this.hideWarning();
    }, 1000 * this.warningTimeout);
  }

  startTimer(duration: number, display: HTMLElement) {
    var timer: number = duration;
    this.timerInterval = setInterval(() => {
        let minutes: number = Math.floor(timer / 60);
        let seconds: number = timer % 60;

        minutes = minutes < 10 ? 0 + minutes : minutes;
        seconds = seconds < 10 ? 0 + seconds : seconds;

        // display.textContent = `${minutes} minutes: ${seconds} seconds`;
        display.textContent = `${minutes} : ${seconds}`;

        if (--timer < 0) {
          clearInterval(this.timerInterval);
        }
    }, 1000);
  }
}
