<div class="container-fluid programs-container">
    <div class="row">
        <div class="programs-header">MENU</div>
        <ul class="nav-list">
            <li class="nav-list-item program-item" *ngFor="let program of this.getProgramList()">
                <a routerLink="/reports" [queryParams]="{program: program}">
                <img src="../../../assets/{{program}}.png" alt="episode" class="program-icon">
                <span>{{ this.authservice.getProgramFullName(program) }}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
