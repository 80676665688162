// This file contains configs for azure dev environment

const oauthIDP = "https://identity.nonprod.onehealthcareid.com/oidc";
const hostRootURL = "https://demo.vbpintelligencehub.com";
//const hostRootURL = "https://vbp-demo.eastus2.cloudapp.azure.com";
const digitalSecurityURL = "https://apps-nonprod.uhcprovider.com/digital-security/stage";

const apiURL = "https://vbp-demo-api.azurewebsites.net" + "/api";

//const apiURL = hostRootURL + "/api";

export const environment = {
  production: false,
  oauthClientId: 'VBP0075942N',
  oauthLoginUrl: oauthIDP + '/authorize',
  oauthTokenUrl: oauthIDP + '/token',
  oauthUserInfoURL: oauthIDP + '/userinfo',
  oauthCallbackUrl: hostRootURL,
  callBackAuthenticated: hostRootURL + '/programs',
  digitalSecurityURL: apiURL + '/digitalsecuirtycreds',
  checkUserRegistry : apiURL + '/checkinternaluser',
  checkUrlMapping : apiURL + '/urlmapping',
  digitalSecurityRegUrl : digitalSecurityURL,
  powerBiEmbedURL : apiURL + '/powerbiembedtoken',
  powerBiExportURL : apiURL + '/powerbiexport',
  powerBiExportStatusURL : apiURL + '/powerbiexportstatus',
  powerBiExportFileURL : apiURL + '/powerbiexportfile',
  grantTypeRefreshToken: 'refresh_token',
  grantTypeAuthorizationCode : 'authorization_code',
};
