import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';

@Component({
  selector: 'app-new-user-and-user-access',
  templateUrl: './new-user-and-user-access.component.html',
  styleUrls: ['./new-user-and-user-access.component.css']
})
export class NewUserAndUserAccessComponent implements OnInit {

  constructor(public authservice: AuthorizationService) { }

  ngOnInit(): void {
  }

}
