import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthorizationInterceptor } from 'src/app/interceptors/authorization.interceptor';
import { HttpResponseCheck } from "src/app/services/httpresponse-check/htttpResponseCheck.service"


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public userActive: Subject<any> = new Subject();
  public userInactive: Subject<any> = new Subject();
  private loggedIn = false;
  private isTokenExpired = false;
  private expirationTime: any;
  private refreshTokenValue = '';
  private uuid = '';
  private programInfo = '';
  private programsAbbreviationMap: any = {
    'EOC': 'Episode of Care',
    'HL': 'Health Link',
    'PCMH': 'Patient Centered Medical Home',
    'TENNSTAR': 'TennStar',
    'EOC Insights': 'Episode of Care Insights',
    'EOC Program Results': 'Episode of Care Program Results',
    'EOC Quality': 'Episode of Care Quality'
  }
  private urlMappings: [];
  private userType = '';
  private tinNumber= '232106895';
  private email = "";

  constructor(
    private storage_token: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private route: Router,
    private httpResCheck: HttpResponseCheck
  ) {
    this.urlMappings = [];
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        this.getAccessToken(params.code, params.state);
      }
    });
    this.storage_token.set('tinNumber', this.tinNumber);
    this.urlMappings = this.storage_token.get('urlMappings') || [];
  }

  public setExpirationTime(timeValue: number) {
    this.expirationTime = timeValue;
  }

  getExpirationTime() {
    return this.expirationTime;
  }

  public setProgram(program: string) {
    this.programInfo = program;
  }

  getProgram() {
    return this.programInfo;
  }

  public setUrlMappings(urlMappings: any) {
    this.urlMappings = urlMappings;
  }

  getUrlMappings() {
    return this.urlMappings;
  }

  public setUserType(userType: string) {
    this.userType = userType;
  }

  getUserType() {
    console.log("usertype",this.userType);
    return this.userType;
  }

  public setTinNumber(tinNumber: string) {
    this.tinNumber = tinNumber;
  }

  getTinNumber() {
    return this.tinNumber;
  }

  getProgramFullName(program: string) {
    console.log("programs:", program);
    return this.programsAbbreviationMap[program];

  }

  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isUserLoggedIn() {
    if (localStorage.getItem('data.access_token')) {
      return true;
    }
    else {
      return false;
    }
  }

  goToLoginPage() {
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128);
    this.storage_token.set('state', state);
    this.storage_token.set('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.oauthClientId,
      'scope=openid+profile+email+address+phone',
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
    ];
    window.location.href = environment.oauthLoginUrl + '?' + params.join('&');
  }

  setUsername(username: string) {
    this.storage_token.set('username', username);
  }

  callDigitalSecuirtyAPI(uuid: string) {
    this.http.get(environment.digitalSecurityURL, {
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      params: new HttpParams({fromObject: {
        uuid: uuid
      }}),
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value]) => {
        // response has MPIN and TINs.
        if (value == 'User Not Found') {
          window.location.href = environment.digitalSecurityRegUrl;
        }
        if (value == 'Vendor') {
          this.route.navigate(['/contact-us']);
        }
        if (value == 'Provider') {
          Object.entries(response.body || {}).forEach(([key, value], index) => {
            if (key == 'program' && value == '') {
              this.route.navigate(['/contact-us']);
            }
            if (key == 'program' && value.$values.length > 0 )
            {
              this.setProgram(value.$values);
              this.storage_token.set('enrolled_programs', this.getProgram());
            }
            if (key == 'userType')
            {
              this.setUserType(value);
              this.storage_token.set('user_type', this.getUserType());
            }
            if (key == 'tinValue')
            {
              console.log("setTinNumber: ", value);
              this.setTinNumber(value);
              this.storage_token.set('tinNumber', this.tinNumber);
            }
          });
        }
      });
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  checkUserInternalExternal(email: string) {
    this.storage_token.set('email', email);
    this.http.get(environment.checkUserRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      console.log("response", response.body);
      this.urlMappings = response.body!.mappings.$values;
      console.log("urlmappings",  this.urlMappings);
      this.storage_token.set('urlMappings', this.urlMappings);
      if (response.body!.internalUser || false) {
        console.log('Internal User');
        console.log('All reports visible#########################################');
        this.setUserType('Plan');
        this.storage_token.set('user_type', this.getUserType());
      }
      else {
        //console.log('External User', this.uuid);
        //console.log(this.uuid);
        this.callDigitalSecuirtyAPI(this.uuid);
      }
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  userLoggedIn(access_token: string) {
    const payload = new HttpParams()
      .append('access_token', access_token) // access token

    this.http.post(environment.oauthUserInfoURL, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Accept": "application/json"
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value], index) => {
        if (key == 'name') {
          this.setUsername(value);
        }
        if (key == 'email') {
          this.email = value;
        }
        if (key == 'sub') {
          this.uuid = value;
        }
      });
      this.checkUserInternalExternal(this.email);
      // this.callDigitalSecuirtyAPI(Object.values(response)[0]);
      // console.log(Object.values(response)[0]);
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  logout(): void {
    this.storage_token.clearAll();
    this.route.navigate(['/']);
  }

  refreshToken() {
    if (this.isTokenExpired) {
      this.isTokenExpired = false;
      this.refreshTokenValue = localStorage.getItem('data.refresh_token') || '';

      const payload = new HttpParams()
        .append('grant_type', environment.grantTypeRefreshToken) // access token
        .append('client_id', environment.oauthClientId)
        .append('refresh_token', this.refreshTokenValue.slice(1, -1))

      this.http.post(environment.oauthTokenUrl, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        observe: 'response',
        responseType: 'json'
      }).subscribe(response => {
        this.httpResCheck.CheckHttpResponseNotify(response);
        //console.log(response);
        Object.entries(response.body || {}).forEach(([key, value], index) => {
          if (key == 'access_token') {
            this.storage_token.set('access_token', value);
          }
        });
      },(error => {
        this.httpResCheck.NotifyError(error.status);
      }));
    }
  }

  setTimeOutForTokenExpiration(expirationTime: number) {
    setTimeout(() => {
      this.isTokenExpired = true;
    }, expirationTime);
  }

  getAccessToken(code: string, state: string) {
    if (state !== this.storage_token.get('state')) {
      alert('Invalid state');
      return;
    }

    const payload = new HttpParams()
      .append('grant_type', environment.grantTypeAuthorizationCode)
      .append('code', code)
      .append('code_verifier', this.storage_token.get('codeVerifier'))
      .append('redirect_uri', environment.oauthCallbackUrl)
      .append('client_id', environment.oauthClientId)
      .append('audience', 'http://localhost:5001');

    this.http.post(environment.oauthTokenUrl, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value], index) => {
        //console.log(response);
        if (key == 'access_token') {
          this.storage_token.set('access_token', value);
          this.loggedIn = true;
          this.userLoggedIn(value);
          this.route.navigate(['/home']);
          AuthorizationInterceptor.accessToken = value;
        }
        if (key == 'refresh_token') {
          this.storage_token.set('refresh_token', value);
        }
        if (key == 'expires_in') {
          this.isTokenExpired = false;
          this.storage_token.set('expires_in', value);
          this.setTimeOutForTokenExpiration(1000 * value);
          this.setExpirationTime(value);
          this.storage_token.set('expires_in', value);
          this.userActive.next(true);
        }
      });
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }
}
