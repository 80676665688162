import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  private urlMappings: any;
  private programList: string[] = [];

  getProgramList() {
    return this.programList;
  }

  constructor(public authservice: AuthorizationService, public localStorage:LocalStorageService) {
  }

  ngOnInit(): void {
  }

  ngDoCheck(){
    this.urlMappings = this.authservice.getUrlMappings();
    this.urlMappings.forEach((report: any) => {
      let program = report.program;
      this.programList.push(program);
    })
    this.programList = [...new Set(this.programList)].sort();
  }
}
