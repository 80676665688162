<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 container-row-margin">
            <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/home" class="text-no-decoration">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4"
            style="background-color: #ededed; border: 2px solid #bfbfbf; height: 15rem;">
            <p class="align-self-center d-flex justify-content-center font-style-bold margin-zero darkblue-color x-font-size"
                style="margin-top: 3px;">Technical Assistance</p>
            <p class="align-self-center d-flex justify-content-center margin-zero darkblue-color"
                style=" font-size: 0.9rem;">Support for navigating and using this site </p>
            <p class="align-self-center d-flex justify-content-center margin-zero darkblue-color"
                style="font-size: 0.9rem;">Email:&NonBreakingSpace;
                <a href="mailto:vbpintelligencehubsolutions@optum.com" class="darkblue-color">vbpintelligencehubsolutions@optum.com</a></p>
            <ul style="margin-top: 2rem;">
                <li class="margin-zero">Registration or sign-in support</li>
                <li class="margin-zero">Representatives available Monday to Friday 9.a.m. to 4 p.m. Central Time</li>
            </ul>
        </div>
        <div class="col-sm-6"
            style="background-color: #ededed; border: 2px solid #bfbfbf;">
            <p class="align-self-center d-flex justify-content-center font-style-bold darkblue-color x-font-size"
                style="margin-top: 3px;">Network Assistance</p>
            <p class="text-center darkblue-color" style="margin-top: -1rem; font-size: 1rem;">Your local network
                management contact is available to answer questions about this site.</p>
            <p class="font-style-bold margin-zero darkblue-color">Network Management</p>
            <p class="font-style-bold margin-zero">Tennessee Network Management-Middle</p>
            <div><span class="font-style-bold margin-zero"> Address:</span>10 Cadillac Drive, Suite 200,
                Brentwood, TN 37027</div>
            <p class="font-style-bold">Fax Number: 1-615-372-3564</p>
            <p class="font-style-bold margin-zero">Tennessee Network Management-Southeast</p>
            <p><span class="font-style-bold"> Address:</span>1208 Pointe Centre Drive, Suite 230, Chattanooga, TN 37421
            </p>

            <p class="font-style-bold margin-zero">Tennessee Network Management - Tri-Cities</p>
            <p><span class="font-style-bold"> Address:</span>2033 Meadowview Lane #300, Kingsport, TN 37660</p>

            <p class="font-style-bold margin-zero">Tennessee Network Management - Memphis</p>
            <p><span class="font-style-bold"> Address:</span>3175 Lenox Park Blvd, Memphis, TN 38115</p>

            <p class="font-style-bold margin-zero darkblue-color">Provider Relations Tennessee Physician &
                Hospital Advocate Team</p>
            <p class="margin-zero"><span class="font-style-bold">Email:</span>
                <a href="mailto:southeastprteam@uhc.com" class="darkblue-color">southeastprteam@uhc.com</a>
            </p>
            <p>Include health care professional / facility name, contact name & phone number, any tracking / reference
                numbers, TIN, and brief description of issue.</p>
        </div>
    </div>
</div>